import { defineMessages } from 'components/LanguageProvider'

const messagesBase = {
  atLeast8CharactersLong: {
    defaultMessage: 'At least 8 characters long',
    id: 'base.atLeast8CharactersLong',
  },
  atLeast12CharactersLong: {
    defaultMessage: 'At least 12 characters long',
    id: 'page.passwordChangeForm.atLeast12CharactersLong',
  },
  atLeastNCharactersLong: {
    defaultMessage: 'At least {n} characters long',
    id: 'page.passwordChangeForm.atLeastNCharactersLong',
  },
  oneLowercaseCharacter: {
    defaultMessage: 'One lowercase character',
    id: 'page.passwordChangeForm.oneLowercaseCharacter',
  },
  oneNumber: {
    defaultMessage: 'One number',
    id: 'page.passwordChangeForm.oneNumber',
  },
  oneSpecialCharacter: {
    defaultMessage: 'One special character',
    id: 'page.passwordChangeForm.oneSpecialCharacter',
  },
  oneUppercaseCharacter: {
    defaultMessage: 'One uppercase character',
    id: 'page.passwordChangeForm.oneUppercaseCharacter',
  },
  symbolOrNumber: {
    defaultMessage: 'A symbol or a number',
    id: 'base.symbolOrNumber',
  },
}

export default defineMessages({
  ...messagesBase,
})
